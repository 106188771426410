import { Component, OnInit, ViewChild, asNativeElements, ElementRef } from '@angular/core';
// import * as ClassicEditor from 'src/assets/ckeditor5-build-classic/build/ckeditor';
import * as InlineEditor from "src/assets/ckeditor5_inline/build/ckeditor.js";


import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { AppService } from '../app.service';

@Component({
  selector: 'app-settings-send-survey',
  templateUrl: './settings-send-survey.component.html',
  styleUrls: ['./settings-send-survey.component.css']
})
export class SettingsSendSurveyComponent implements OnInit {
  //   @ViewChild('send_email_color',{static:false}) send_email_color:ElementRef;
  //   @ViewChild('send_phone_color',{static:false}) send_phone_color:ElementRef;
  //   @ViewChild('send_submit_color',{static:false}) send_submit_color:ElementRef;

  public send_email_color = "#ffffff";
  public send_email_bg_color = "#17a2b8";
  public send_phone_color = "#ffffff";
  public send_phone_bg_color = "#17a2b8";
  public send_submit_color = "#ffffff";
  public send_submit_bg_color = "#17a2b8";

  public Editor = InlineEditor;
  public ckconfig = {
    placeholder: 'Insert text here',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'link',
        'alignment',
        '|',
        'fontBackgroundColor',
        'fontColor',
        'fontSize',
        'fontFamily',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    fontSize: {
      options: [
      'default',
      this.generateFontSizeSetting('6'),
      this.generateFontSizeSetting('7'),
      this.generateFontSizeSetting('8'),
      this.generateFontSizeSetting('9'),
      this.generateFontSizeSetting('10'),
      this.generateFontSizeSetting('11'),
      this.generateFontSizeSetting('12'),
      this.generateFontSizeSetting('13'),
      this.generateFontSizeSetting('14'),
      this.generateFontSizeSetting('15'),
      this.generateFontSizeSetting('16'),
      this.generateFontSizeSetting('17'),
      this.generateFontSizeSetting('18'),
      this.generateFontSizeSetting('19'),
      this.generateFontSizeSetting('20'),
      this.generateFontSizeSetting('21'),
      this.generateFontSizeSetting('22'),
      this.generateFontSizeSetting('23'),
      this.generateFontSizeSetting('24'),
      this.generateFontSizeSetting('25'),
      this.generateFontSizeSetting('26'),
      this.generateFontSizeSetting('27'),
      this.generateFontSizeSetting('28'),
      this.generateFontSizeSetting('29'),
      this.generateFontSizeSetting('30'),
      this.generateFontSizeSetting('31'),
      this.generateFontSizeSetting('32'),
      this.generateFontSizeSetting('33'),
      this.generateFontSizeSetting('34'),
      this.generateFontSizeSetting('35'),
      this.generateFontSizeSetting('36'),
      this.generateFontSizeSetting('37'),
      this.generateFontSizeSetting('38'),
      this.generateFontSizeSetting('39'),
      this.generateFontSizeSetting('40'),
      this.generateFontSizeSetting('41'),
      this.generateFontSizeSetting('42'),
      this.generateFontSizeSetting('43'),
      this.generateFontSizeSetting('44'),
      this.generateFontSizeSetting('45'),
      this.generateFontSizeSetting('46'),
      this.generateFontSizeSetting('47'),
      this.generateFontSizeSetting('48'),
      this.generateFontSizeSetting('49'),
      this.generateFontSizeSetting('50'),
      this.generateFontSizeSetting('51'),
      this.generateFontSizeSetting('52'),
      this.generateFontSizeSetting('53'),
      this.generateFontSizeSetting('54'),
      this.generateFontSizeSetting('55'),
      this.generateFontSizeSetting('56'),
      this.generateFontSizeSetting('57'),
      this.generateFontSizeSetting('58'),
      this.generateFontSizeSetting('59'),
      this.generateFontSizeSetting('60'),

      ]
    },

    fontFamily: {
      options: [
        'default',
        'Ubuntu, Arial, sans-serif',
        'Ubuntu Mono, Courier New, Courier, monospace',
        'DIN W05 Light, Arial, sans-serif',
        'DIN W05 Regular, Arial, sans-serif',
        'DIN W05 Regular Italic, Arial, sans-serif',
        'DIN W05 Medium, Arial, sans-serif',
        'DIN W05 Bold, Arial, sans-serif',
        'DIN W05 Black, Arial, sans-serif',
        'DIN W05 Cond Bold, Arial, sans-serif',
        'Inter-Regular, Arial, sans-serif',
        'Inter-Bold, Arial, sans-serif',
        'Inter-Semi-Bold, Arial, sans-serif',
        'Inter-Medium, Arial, sans-serif',
        'Inter-Light, Arial, sans-serif',
        'Inter-Italic, Arial, sans-serif',
      ]
    },
    language: 'en',
    image: {
      // You need to configure the image toolbar, too, so it uses the new style buttons.
      toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

      styles: [
        // This option is equal to a situation where no style is applied.
        'full',

        // This represents an image aligned to the left.
        'alignLeft',

        // This represents an image aligned to the right.
        'alignRight'
      ]
    },
    licenseKey: '',

  }

  constructor(private _service: AppService) { }
  public sendEmailText = 'Email';
  public sendPhoneText = 'Phone #';
  public sendSubmitText = 'Submit';

  ngOnInit() {
  }

  public sendEmailhandler(action: String) {
    switch (action) {

      case "label":
        this._service.eventManager.emit(this._service.SEND_EMAIL_LABEL, this.sendEmailText);
        break;

      case "color":
        this._service.eventManager.emit(this._service.SEND_EMAIL_COLOR, this.send_email_color);
        break;

      case "bg_color":
        this._service.eventManager.emit(this._service.SEND_EMAIL_BG_COLOR, this.send_email_bg_color);
        break;
    }
  }

  public sendPhonehandler(action: String) {
    switch (action) {

      case "label":
        this._service.eventManager.emit(this._service.SEND_PHONE_LABEL, this.sendPhoneText);
        break;

      case "color":
        this._service.eventManager.emit(this._service.SEND_PHONE_COLOR, this.send_phone_color);
        break;

      case "bg_color":
        this._service.eventManager.emit(this._service.SEND_PHONE_BG_COLOR, this.send_phone_bg_color);
        break;
    }
  }

  public sendSubmithandler(action: String) {
    switch (action) {

      case "label":
        this._service.eventManager.emit(this._service.SEND_SUBMIT_TEXT, this.sendSubmitText);
        break;

      case "color":
        this._service.eventManager.emit(this._service.SEND_SUBMIT_COLOR, this.send_submit_color);
        break;

      case "bg_color":
        this._service.eventManager.emit(this._service.SEND_SUBMIT_BG_COLOR, this.send_submit_bg_color);
        break;
    }
  }

  public onSelectionChange({ editor }: ChangeEvent): void {
    const data = editor.getData();
    this._service.eventManager.emit(this._service.SEND_SELECTION_CONTENT, data);
  }

  public onInputChange({ editor }: ChangeEvent): void {
    const data = editor.getData();
    this._service.eventManager.emit(this._service.SEND_INPUT_CONTENT, data);
  }
  generateFontSizeSetting( size ) {
    return {
      model: size,
      title: size,
      view: {
        name: 'span',
        styles: {
          'font-size': `${ size }pt`
        }
      }
    };
  }


}
