import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Project } from "../models/project.model";
import { Page } from "../models/page.model";

declare var $;

@Component({
  selector: "app-popup-ds",
  templateUrl: "./popup-ds.component.html",
  styleUrls: ["./popup-ds.component.css"],
})
export class AppPopupDsComponent implements OnInit, AfterViewInit {
  @ViewChild("elDelayedContent") elDelayedContent: ElementRef;

  project: Project;
  selectedPage: Page;

  constructor() {}

  ngOnInit(): void {
    this.project = JSON.parse(localStorage.getItem("project"));
    this.selectedPage = this.project.pages[0];
  }

  ngAfterViewInit(): void {
    this.elDelayedContent.nativeElement.innerHTML = this.parseHTML(
      this.selectedPage.surveyContent.htmlContent
    );
  }

  closePopup() {
    $("#popupDSModal").modal("hide");
  }

  parseHTML(content: string) {
    let _content = new DOMParser().parseFromString(content, "text/html");

    let figures = _content.getElementsByTagName("figure");

    for (let i = 0; i < figures.length; i++) {
      let figure = figures[i];
      if (figure) {
        let oldFigure = figure.outerHTML;
        let image = figure.getElementsByTagName("img")[0];
        image.style.display = "block";
        image.style.margin = "0 auto";
        image.style.maxWidth = "100%";
        image.style.minWidth = "50px";

        figure.classList.forEach((cls) => {
          switch (cls) {
            case "image":
              figure.style.display = "table";
              figure.style.clear = "both";
              figure.style.textAlign = "center";
              figure.style.margin = "1em auto";
              figure.classList.remove("image");
              break;
            case "image_resized":
              figure.style.maxWidth = "100%";
              figure.style.display = "block";
              figure.style.boxSizing = "border-box";
              figure.classList.remove("image_resized");
              break;
          }
        });

        let newFigure = "";

        if (figure.outerHTML.includes("image-style-align-left")) {
          figure.style.cssFloat = "left";
          newFigure = figure.outerHTML.replace("image-style-align-left", "");
        } else if (figure.outerHTML.includes("image-style-align-right")) {
          figure.style.cssFloat = "right";
          newFigure = figure.outerHTML.replace("image-style-align-right", "");
        } else {
          newFigure = figure.outerHTML;
        }

        newFigure = newFigure.split("figure").join("div");

        if (content.includes(oldFigure)) {
          content = content.replace(oldFigure, newFigure);
        }
      }
    }
    return content;
  }
}
