import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { Project } from "../models/project.model";
import { Page } from "../models/page.model";
import { PackageService } from "../services/package.service";

declare var $;
@Component({
  selector: "app-live-preview",
  templateUrl: "./live-preview.component.html",
  styleUrls: ["./live-preview.component.css"]
})
export class LivePreviewComponent implements OnInit, AfterViewInit {
  dangerousVideoUrl: string;
  data: SafeResourceUrl;

  OptionView = "Background";
  SiteData = "";
  color = "#ededed";
  modalViewer = true;
  BGIpath = "";
  delayModal = true;
  FabModal = true;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  project: Project;
  selectedPage: Page;
  selectedPageIndex: number;
  x: any;
  y: any;

  Fab_style = {
    position: "fixed",
    float: "right",
    left: "1300px",
    top: "600px",
    // 'border': 'grey solid 1px',
    display: "block",
    outline: "none",
    border: "none"
  };
  modal_style = {
    display: "block",
    position: "fixed",
    // 'width': '370px',
    // 'height': '470px',
    float: "right",
    top: "110px",
    left: "1100px",
    // 'border': 'grey solid 1px',
    "background-color": "white"
  };

  isLoading: boolean;
  errorMsg: string;

  @ViewChild("sidenav") sidenav: MatSidenav;
  showFAB: boolean;

  constructor(
    public DomSanitizer: DomSanitizer,
    private PackageService: PackageService
  ) {}

  ngOnInit(): void {
    this.project = JSON.parse(localStorage.getItem("project"));
    if (this.project.pages.length > 0) this.updateSelectedPage();
  }

  ngAfterViewInit(): void {}

  updateSelectedPage() {
    this.selectedPage = this.project.pages[this.selectedPageIndex];
  }

  loadPopup() {
    $("#popupModal").modal("show");
  }

  loadUrl() {
    let _this = this;
    this.isLoading = true;
    let timeout = null;

    document
      .getElementById("website-container")
      .addEventListener("load", function(event) {
        _this.isLoading = false;
        _this.errorMsg = null;
        clearTimeout(timeout);
      });

    timeout = setTimeout(() => {
      this.isLoading = false;
      this.errorMsg = "Cannot load website due to network issues.";
    }, 15000);

    this.dangerousVideoUrl = this.SiteData;
    this.data = this.DomSanitizer.bypassSecurityTrustResourceUrl(
      this.dangerousVideoUrl
    );
  }

  displayFAB() {
    this.showFAB = true;
  }

  imageUpload(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = "Maximum size allowed is " + max_size / 1000 + "Mb";

        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  ShowDS() {
    const posi = document.getElementById("btn_Fab").getBoundingClientRect();
    this.x = posi.x;
    this.y = posi.y;
    if (this.y >= 301.8) {
      this.y = this.y - this.selectedPage.surveyContent.modalHeight - 20;
    } else {
      this.y = this.y + this.selectedPage.surveyContent.floatingBtnHeight + 20;
    }

    if (this.x >= 599.9) {
      this.x =
        this.x -
        this.selectedPage.surveyContent.modalWidth +
        this.selectedPage.surveyContent.floatingBtnWidth;
    }
    this.modal_style.top = this.y + "px";
    this.modal_style.left = this.x + "px";
    this.FabModal = !this.FabModal;
    // (this.modal_style.top = this.y + 'px', '::',
    // this.modal_style.left = this.x + 'px')
  }
}
