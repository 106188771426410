import {
  ProjectSetting
} from './project-setting.model';
import {
  Page
} from './page.model';

export class Project {
  [x: string]: any;

  settings: ProjectSetting;
  pages: Array < Page > ;

  constructor() {
    this.settings = new ProjectSetting;
    this.pages = [];
  }

}