import { Tracking } from './tracking.model';
import { Invite } from './invite.model';
import { Popunder } from './popunder.model';
import { PopupModal } from './popup-modal.model';

export class ProjectSetting {

    tracking: Tracking;
    invite: Invite;
    popunder: Popunder;
    popupModal: PopupModal;
    isSPA: boolean;

    constructor() {
        this.tracking = new Tracking();
        this.invite = new Invite();
        this.popunder = new Popunder();
        this.popupModal = new PopupModal();
        this.isSPA = true;
    }

}