import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { ProjectService } from "../services/project.service";
import { Project } from "../models/project.model";
import { CookieService } from "ngx-cookie-service";

declare var $;

@Component({
  selector: "app-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.css"],
})
export class ShellComponent implements OnInit {
  @ViewChild("elProjectID") elProjectID: ElementRef;

  public projectName: string;
  public hasNewSurveyPopup: boolean;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private ProjectService: ProjectService,
    private CookieService: CookieService
  ) {}

  ngOnInit() {
    this.showProjectIDModal();
  }

  initProps() {
    this.projectName = "";
    this.hasNewSurveyPopup = false;
  }

  showProjectIDModal() {
    this.initProps();
    $("#projectIDModal").modal("show");
  }

  hideProjectIDModal() {
    $("#projectIDModal").modal("hide");
  }

  createSurveyPopup() {
    if (this.projectName) {
      this.ProjectService.project = new Project();
      this.ProjectService.project.settings.invite.borderRadius = 0;
      this.ProjectService.project.settings.popupModal.width = 500;
      this.ProjectService.project.settings.popupModal.height = 400;
      this.ProjectService.project.settings.tracking.cookieDuration = 30;

      this.ProjectService.saveToLocalStorage();

      this.hasNewSurveyPopup = true;
      this.hideProjectIDModal();
    } else this.renderer.addClass(this.elProjectID.nativeElement, "is-invalid");
  }

  doLogout() {
    this.initProps();
    this.router.navigateByUrl("/");
    this.CookieService.delete("user");
  }
}
