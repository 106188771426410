<div class="modal fade" tabindex="-1" role="dialog" id="projectIDModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">New Survey Popup</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="project-id" class="col-form-label">Project name:</label>
            <input
              #elProjectID
              type="text"
              class="form-control"
              id="project-id"
              name="project-id"
              [(ngModel)]="projectName"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="createSurveyPopup()"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</div>

<mat-sidenav-container
  class="sidenav-backdrop"
  (backdropClick)="sidenav.close()"
>
  <mat-sidenav
    color="primary"
    #sidenav
    (keydown.escape)="sidenav.close()"
    disableClose
    style="width: 350px;"
  >
    <div class="uk-text-center uk-margin-large-top">
      <img src="assets/img/concentrix_research_ops.png" width="150" />
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <span>
        <button mat-icon-button (click)="sidenav.open()">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
      <span>
        User Portal
      </span>
      <span style="flex: auto 1 1;"></span>
      <span>
        <button
          class="uk-margin-right"
          mat-icon-button
          matTooltip="Logout"
        >
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </span>
    </mat-toolbar>
    <div class="container-fluid">
      <!-- <div class="row">
        <div class="col-sm" style="padding: 0;">
          <ul
            class="nav justify-content-center"
            style="
              padding: 0;
              height: 60px;
              align-items: center;
              background-color: rgb(214, 214, 214);
            "
          >
            <li class="nav-item">
              
            </li>
          </ul>
        </div>
      </div> -->

      <div class="row" *ngIf="!hasNewSurveyPopup">
        <div class="col-sm d-flex justify-content-center mt-5">
          Create new survey popup&nbsp;<a
            [routerLink]=""
            (click)="showProjectIDModal()"
            >here</a
          >.
        </div>
      </div>

      <div
        class="row-sm d-flex justify-content-center mt-5"
        *ngIf="!hasNewSurveyPopup"
      >
        <button type="button" class="btn btn-info" (click)="doLogout()">
          Logout
        </button>
      </div>

      <div class="row" *ngIf="hasNewSurveyPopup">
        <div class="col-sm border" style="min-height: 400px;">
          <!-- <app-webpop-settings></app-webpop-settings> -->
        </div>
        <div class="col-sm border">
          <!-- <app-webpop-preview></app-webpop-preview> -->
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <div id="modal-user-logout" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <div class="uk-modal-header">
      <h2 class="uk-modal-title">
        Logout
      </h2>
    </div>
    <div class="uk-modal-body">
      <span>Are you sure you want to logout?</span>
    </div>
    <div class="uk-modal-footer uk-text-right">
      <button
        mat-flat-button
        color="accent"
        class="uk-margin-small-right uk-modal-close"
        (click)="doLogout()"
      >
        Yes
      </button>
      <button mat-flat-button color="primary" class="uk-modal-close">
        Cancel
      </button>
    </div>
  </div>
</div> -->
