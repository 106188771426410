<div>
    <hr/>
    <!-- Header -->
    <!--div class="card">
      <div class="card-header text-white bg-info mb-3">
        Reach via
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col">
            <select class="form-control">
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>                          
          </div>
        </div>
        </div>
      </div>
    <hr/ -->

    <div class="card">
      <div class="card-header text-white bg-info mb-3">
        Selection Content
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col">
              <ckeditor [editor]="Editor" [config]="ckconfig" (change)="onSelectionChange($event)"></ckeditor>
          </div>
        </div>
        </div>
      </div>
      <hr/>

      <div class="card">
          <div class="card-header text-white bg-info mb-3">
            Input Content
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col">
                  <ckeditor [editor]="Editor" [config]="ckconfig" (change)="onInputChange($event)"></ckeditor>
              </div>
            </div>
            </div>
          </div>
          <hr/>

    <!-- Action Buttons -->
    <div class="card">
        <div class="card-header text-white bg-info mb-3">
          Action Buttons
        </div>
        <div class="card-body">
  
            <div class="form-row">
                <div class="form-group col-2-5">
                  <label for="sendEmailText">Email Button Text / Color</label>
                  <input #send_email_text type="text" [(ngModel)]="sendEmailText" (ngModelChange)="sendEmailhandler('label');" class="form-control" name="sendEmailText" value="EMAIL"/>
                </div>
                <div class="form-group col-1-5 left-divider">
                  <label for="primaryButtonTextColor">Text Color</label>
                  <button class="pickerstyle form-control" name="primaryButtonTextColor"
                    [style.background]="send_email_color"
                    [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                    [(colorPicker)]="send_email_color" [cpPosition]="'left'"
                    (colorPickerChange)="sendEmailhandler('color')"></button>
                </div>
                <div class="form-group col-1-5 left-divider">
                  <label for="primaryButtonBGColor">Background Color</label>
                  <button class="pickerstyle form-control" name="primaryButtonBGColor"
                    [style.background]="send_email_bg_color"
                    [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                    [(colorPicker)]="send_email_bg_color" [cpPosition]="'left'"
                    (colorPickerChange)="sendEmailhandler('bg_color')"></button>
                </div>
                <!-- <div class="form-group col-1-5">
                  <div style="margin-top: 31px;">
                    <select #send_email_color (change)="sendEmailhandler('color');" class="form-control" name="send_email_color">
                      <option value="primary">Blue</option>
                      <option value="secondary">Grey</option>
                      <option value="success">Green</option>
                      <option value="danger">Red</option>
                      <option value="warning">Yelow</option>
                      <option selected value="info">Teal</option>
                      <option value="dark">Black</option>
                    </select>                            
                  </div>
                </div> -->
              </div>

              <div class="form-row">
                  <div class="form-group col-2-5">
                    <label for="sendPhoneText">Phone Button Text</label>
                    <input #send_phone_text type="text" [(ngModel)]="sendPhoneText" (ngModelChange)="sendPhonehandler('label');" class="form-control" name="sendPhoneText"/>
                  </div>
                  <div class="form-group col-1-5 left-divider">
                    <label for="primaryButtonTextColor">Text Color</label>
                    <button class="pickerstyle form-control" name="primaryButtonTextColor"
                      [style.background]="send_phone_color"
                      [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                      [(colorPicker)]="send_phone_color" [cpPosition]="'left'"
                      (colorPickerChange)="sendPhonehandler('color')"></button>
                  </div>
                  <div class="form-group col-1-5 left-divider">
                    <label for="primaryButtonBGColor">Background Color</label>
                    <button class="pickerstyle form-control" name="primaryButtonBGColor"
                      [style.background]="send_phone_bg_color"
                      [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                      [(colorPicker)]="send_phone_bg_color" [cpPosition]="'left'"
                      (colorPickerChange)="sendPhonehandler('bg_color')"></button>
                  </div>
                  <!-- <div class="form-group col-1-5">
                    <div style="margin-top: 31px;">
                      <select #send_phone_color (change)="sendPhonehandler('color');" class="form-control" name="send_phone_color">
                        <option value="primary">Blue</option>
                        <option value="secondary">Grey</option>
                        <option value="success">Green</option>
                        <option value="danger">Red</option>
                        <option value="warning">Yelow</option>
                        <option selected value="info">Teal</option>
                        <option value="dark">Black</option>
                      </select>                            
                    </div>
                  </div> -->
                </div> 

                <div class="form-row">
                    <div class="form-group col-2-5">
                      <label for="sendSubmitText">Send Button Text / Color</label>
                      <input #send_submit_text type="text" [(ngModel)]="sendSubmitText" (ngModelChange)="sendSubmithandler('label');" class="form-control" name="sendSubmitText"/>
                    </div>
                    <div class="form-group col-1-5 left-divider">
                      <label for="primaryButtonTextColor">Text Color</label>
                      <button class="pickerstyle form-control" name="primaryButtonTextColor"
                        [style.background]="send_submit_color"
                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                        [(colorPicker)]="send_submit_color" [cpPosition]="'left'"
                        (colorPickerChange)="sendSubmithandler('color')"></button>
                    </div>
                    <div class="form-group col-1-5 left-divider">
                      <label for="primaryButtonBGColor">Background Color</label>
                      <button class="pickerstyle form-control" name="primaryButtonBGColor"
                        [style.background]="send_submit_bg_color"
                        [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                        [(colorPicker)]="send_submit_bg_color" [cpPosition]="'left'"
                        (colorPickerChange)="sendSubmithandler('bg_color')"></button>
                    </div>
                    <!-- <div class="form-group col-1-5">
                      <div style="margin-top: 31px;">
                        <select #send_submit_color (change)="sendSubmithandler('color');" class="form-control" name="send_submit_color">
                          <option value="primary">Blue</option>
                          <option value="secondary">Grey</option>
                          <option value="success">Green</option>
                          <option value="danger">Red</option>
                          <option value="warning">Yelow</option>
                          <option selected value="info">Teal</option>
                          <option value="dark">Black</option>
                        </select>                            
                      </div>
                    </div> -->
                </div>
        </div>
      </div>
  </div>