import { Injectable } from "@angular/core";
import { User } from "../models/user.model";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class UserService {

  constructor(private http: HttpClient) {}

  get(clientID: string) {
    return this.http.get(`${environment.apiURL}/v1/user/?clientID=${clientID}`);
  }

  post(user: User) {
    const body = {
      username: user.username,
      password: user.password,
      email: user.email,
      userType: user.userType,
      failedLogins: user.failedLogins,
      isActive: user.isActive,
      isDeleted: user.isDeleted,
      clientID: user.clientID,
    };

    return this.http.post(`${environment.apiURL}/v1/user/add`, body);
  }

  put(user: User) {
    const body = {
      id: user.id,
      username: user.username,
      password: user.password,
      email: user.email,
      userType: user.userType,
      failedLogins: user.failedLogins,
      isActive: user.isActive,
      isDeleted: user.isDeleted,
      clientID: user.clientID,
    };

    return this.http.put(`${environment.apiURL}/v1/user/update`, body);
  }

  isUsernameTaken(username: string) {
    return this.http.get(
      `${environment.apiURL}/v1/user/is-username-taken/?username=${username}`
    );
  }

  unlockUser(userID: string) {
    return this.http.get(
      `${environment.apiURL}/v1/user/unlock-user/?userID=${userID}`
    );
  }
}
