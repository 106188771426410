import { Injectable } from "@angular/core";
import { Project } from "../models/project.model";
import { EventEmitter } from "events";
import { CookieService } from "ngx-cookie-service";
import { ProjectService2 } from "../shared/services";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  project: Project;
  projectOptions: any;
  projectID: number;

  selectedPageIndex: number;
  launcherView: string;
  pageType: string;
  selectedSendInviteTab: string;
  selectedPageIDs: Array<number>;

  projectEventManager: EventEmitter;
  setupSettings: any;

  EVENT = {
    HTML_CONTENT_UPDATED: "html-content-updated",
  };

  VARIANT = {
    VARIANT_TYPE1: "variant1",
    VARIANT_TYPE2: "variant2",
  };

  CONTENT = {
    INVITE: 0, // Immediate Survey
    DELAYED: 1, // Delayed Survey

    SEND_SELECT: 2,
    SEND_INPUT: 3, // Send Invite
    LATER_SURVEY: 4,
    SUCCESS_MESSAGE: 5,

    INVITE_BANNER: 6, // Immediate Survey

    LATER_SURVEY_BANNER: 7,

    DELAYED_BANNER: 8, // Delayed Survey

    SELECTION_BANNER: 9,
    EMAIL_BANNER: 10,
    PHONE_BANNER: 11,
    SUCCESS_BANNER: 12,

    POPUNDER: 13, // Pop Under
    POPUNDER_BANNER: 14, // Pop Under

    POPOVER: 15, //Pop Over
    POPOVER_BANNER: 16, //Pop Over


    FEEDBACK_TAB: 17
  };

  CONTENT2 = {
    INVITE: 17, // Immediate Survey
    DELAYED: 18, // Delayed Survey

    SEND_SELECT: 19,
    SEND_INPUT: 20, // Send Invite
    LATER_SURVEY: 21,
    SUCCESS_MESSAGE: 22,

    INVITE_BANNER: 23, // Immediate Survey

    LATER_SURVEY_BANNER: 24,

    DELAYED_BANNER: 25, // Delayed Survey

    SELECTION_BANNER: 26,
    EMAIL_BANNER: 27,
    PHONE_BANNER: 28,
    SUCCESS_BANNER: 29,

    POPUNDER: 30, // Pop Under
    POPUNDER_BANNER: 31, // Pop Under

    POPOVER: 32,
    POPOVER_BANNER: 33,
  };

  constructor(
    private CookieService: CookieService,
    private ProjectService: ProjectService2
  ) {
    this.project = new Project();
    this.selectedPageIndex = -1;
    this.launcherView = "invite";
    this.pageType = "Pages";
    this.selectedSendInviteTab = "select";
    this.selectedPageIDs = [];

    this.projectEventManager = new EventEmitter();
  }
  cleardata() {
    this.project = new Project();
    this.selectedPageIndex = -1;
    this.launcherView = "invite";
    this.pageType = "Pages";
    this.selectedSendInviteTab = "select";
    this.selectedPageIDs = [];

    this.projectEventManager = new EventEmitter();
  }
  
  saveToLocalStorage() {
    localStorage.setItem("project", JSON.stringify(this.project));
  }
}
