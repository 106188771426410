import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { ProjectService } from "./project.service";

@Injectable({
  providedIn: "root",
})
export class PackageService {
  // options = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/json, text/plain'
  //   })
  // }

  constructor(
    private httpClient: HttpClient,
    private ProjectService: ProjectService
  ) {}

  tryURL(url: string): Observable<any> {
    return this.httpClient.get<any>(url);
  }

  sendSettingsJSON(files: any, settings_json: any, pageIds: number[]): Observable<any> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append("files", file);
    })
    formData.append("projectPrefix", this.ProjectService.projectOptions.jobNamePrefix);
    formData.append("isSPA", this.ProjectService.projectOptions.isSPA);
    formData.append("pageTotal", settings_json.pages.length.toString());
    formData.append("projectId", this.ProjectService.projectID.toString());
    formData.append(
      "rootDirectory",
      this.ProjectService.projectOptions.rootDirectory
    );
    // formData.append("projectId", );
    pageIds.forEach(pageId => {
      formData.append("pageIds", pageId.toString());
    });
    settings_json.pages.forEach((page, index) => {
      formData.append("pageId" + (index + 1), page.pageId);
      formData.append("pageRoute" + (index + 1), page.pageURL);
      if (this.ProjectService.projectOptions.isSPA) {
      }
      // formData.append("pageCategory"  + (index + 1), page.pageCategory);
      formData.append(
        "pageName" + (index + 1),
        page.pageName
          .toLowerCase()
          .replace(/'/g, "")
          .replace(/"/g, "")
          .replace(/ /g, "_")
      );
      formData.append(
        "Nth" + (index + 1),
        _.round(page.Nth / 100, 2).toFixed(2)
      );
    });

    // return this.httpClient.post<any>(`${this.apiURL}generate`, formData);
    // return this.httpClient.post<any>('http://localhost:3000/generate', formData);
    return this.httpClient.post<any>(
      `${environment.apiURL}/v1/generate/`,
      formData
    );
  }

  PublishSettingsJSON(
    files: any,
    settings_json: any,
    clientName: any,
    targetEnv:any,
    contentType:any,
    pageIds: any
  ): Observable<any> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append("files", file);
    })
    pageIds.forEach(pageId => {
      formData.append("pageIds", pageId.toString());
    });
    formData.append("projectPrefix", this.ProjectService.projectOptions.jobNamePrefix);
    formData.append("isSPA", this.ProjectService.projectOptions.isSPA);
    formData.append("pageTotal", settings_json.pages.length.toString());
    formData.append("projectId", this.ProjectService.projectID.toString());
    formData.append("targetEnv", targetEnv);
    formData.append("contentType", contentType);
    formData.append(
      "rootDirectory",
      this.ProjectService.projectOptions.rootDirectory
    );
    formData.append("clientName", clientName);
    // formData.append("projectId", );

    settings_json.pages.forEach((page, index) => {
      formData.append("pageId" + (index + 1), page.pageId);
      formData.append("pageRoute" + (index + 1), page.pageURL);
      if (this.ProjectService.projectOptions.isSPA) {
      }
      // formData.append("pageCategory"  + (index + 1), page.pageCategory);
      formData.append(
        "pageName" + (index + 1),
        page.pageName
          .toLowerCase()
          .replace(/'/g, "")
          .replace(/"/g, "")
          .replace(/ /g, "_")
      );
      formData.append(
        "Nth" + (index + 1),
        _.round(page.Nth / 100, 2).toFixed(2)
      );
    });

    // return this.httpClient.post<any>(`${this.apiURL}generate`, formData);
    // return this.httpClient.post<any>('http://localhost:3000/generate', formData);
    return this.httpClient.post<any>(
      `${environment.apiURL}/v1/generate/pushToCDN`,
      formData
    );
  }

  getPackageZip(path: string): Observable<any> {
    return this.httpClient.get<any>(path, {
      responseType: "arraybuffer" as "json",
    });
  }
}
