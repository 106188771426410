import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
// import { WebpopSettingsComponent } from "./webpop-settings/webpop-settings.component";
// import { WebpopPreviewComponent } from "./webpop-preview/webpop-preview.component";

import { ColorPickerModule } from "ngx-color-picker";
import { AppService } from "./app.service";
import { LoginComponent } from "./login/login.component";
import { ShellComponent } from "./shell/shell.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SettingsDelayedSurveyComponent } from "./settings-delayed-survey/settings-delayed-survey.component";
import { SettingsSendSurveyComponent } from "./settings-send-survey/settings-send-survey.component";
// import { TabLauncherComponent } from "./tab-launcher/tab-launcher.component";
// import { TabSettingsComponent } from "./tab-settings/tab-settings.component";
// import { TabGenerateComponent } from "./tab-generate/tab-generate.component";
// import { TabInviteComponent } from "./tab-invite/tab-invite.component";
import { ProjectService } from "./services/project.service";
import { PackageService } from "./services/package.service";
// import { PopupComponent } from "./popup/popup.component";
// import { LivePreviewComponent } from "./live-preview/live-preview.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
// import { AppPopupDsComponent } from "./popup-ds/popup-ds.component";
import { AngularDraggableModule } from "angular2-draggable";
import {
  ClientService,
  ProjectService2,
  UserService,
  AdminStateService,
  UserAuthService,
} from "src/app/shared/services";
import { AngularMaterialModule } from "src/app/shared/modules/angular-material.module";
import { environment } from "src/environments/environment";
import { SnackBarComponent } from "./shared/components/snack-bar/snack-bar.component";
import { CookieService } from "ngx-cookie-service";
import { ImageCompressionService } from "./shared/services/image-compression-service";

@NgModule({
  declarations: [
    AppComponent,
    // WebpopSettingsComponent,
    // WebpopPreviewComponent,
    LoginComponent,
    ShellComponent,
    SettingsDelayedSurveyComponent,
    SettingsSendSurveyComponent,
    // TabLauncherComponent,
    // TabSettingsComponent,
    // TabGenerateComponent,
    // TabInviteComponent,
    // LivePreviewComponent,
    // PopupComponent,
    // AppPopupDsComponent,
    SnackBarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ColorPickerModule,
    CKEditorModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    AngularDraggableModule,
    AngularMaterialModule,
  ],
  providers: [
    AppService,
    ProjectService,
    PackageService,
    ClientService,
    ProjectService2,
    UserService,
    UserAuthService,
    AdminStateService,
    CookieService,
    ImageCompressionService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
