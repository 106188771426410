export class Popunder {

    height: number;
    width: number;

    constructor() {
        this.height = null;
        this.width = null;
    }

}