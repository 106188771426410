import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  constructor(private http: HttpClient) {}

  isUserAuthenticated(username: string, password: string) {
    return this.http.post(
      `${environment.apiURL}/v1/user/is-user-authenticated`, 
      {
        username, 
        password
      }
    );
  }

  forgotPassword(payload: any) {
    return this.http.get(
      `${environment.apiURL}/v1/user/forgotpassword/?email=${payload.email}`
    );
  }

}
