import { Injectable } from "@angular/core";
import { Client } from "../models/client.model";
import { EventEmitter } from "events";
@Injectable({
  providedIn: "root",
})
export class AdminStateService {
  eventHandler: EventEmitter;

  SELECTED_CLIENT_CHANGE = 'selected client change';
  DISABLED_SELECTED_CLIENT = 'disabled selected client';

  isLoadingProjects: boolean;
  isLoadingUsers: boolean;

  numOfActiveProjects: number;
  numOfActiveUsers: number;

  constructor() {
    this.eventHandler = new EventEmitter();
  }
}
