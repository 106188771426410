<div>
  <hr />
  <!-- Header -->
  <div class="card">
    <div class="card-header text-white bg-info mb-3">
      Delayed Content
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col">
          <ckeditor [editor]="Editor" [config]="ckconfig" (change)="onChange($event)"></ckeditor>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <!-- Action Buttons -->
  <div class="card">
    <div class="card-header text-white bg-info mb-3">
      Action Buttons
    </div>
    <div class="card-body">

      <div class="form-row">
        <div class="form-group col-2-5">
          <label for="primaryButtonText">Primary Button Text</label>
          <input #delayed_pbutton_text type="text" [(ngModel)]="primaryButtonText"
            (ngModelChange)="p_button_handler('label');" class="form-control" name="primaryButtonText"
            value="Primary Button" />
        </div>
        <div class="form-group col-1-5 left-divider">
          <label for="primaryButtonTextColor">Text Color</label>
          <button class="pickerstyle form-control" name="primaryButtonTextColor" [style.background]="delayed_pbutton_color"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [(colorPicker)]="delayed_pbutton_color" [cpPosition]="'left'"
            (colorPickerChange)="p_button_handler('color')"></button>
        </div>
        <div class="form-group col-1-5 left-divider">
          <label for="primaryButtonBGColor">Background Color</label>
          <button class="pickerstyle form-control" name="primaryButtonBGColor" [style.background]="delayed_pbutton_bg_color"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [(colorPicker)]="delayed_pbutton_bg_color" [cpPosition]="'left'"
            (colorPickerChange)="p_button_handler('bg_color')"></button>
        </div>
        <!-- <div class="form-group col-1-5">
                <div style="margin-top: 31px;">
                  <select #delayed_pbutton_color (change)="p_button_handler('color');" class="form-control" name="delayed_pbutton_color">
                    <option value="primary">Blue</option>
                    <option value="secondary">Grey</option>
                    <option value="success">Green</option>
                    <option value="danger">Red</option>
                    <option value="warning">Yelow</option>
                    <option value="info">Teal</option>
                    <option value="dark">Black</option>
                  </select>                            
                </div>
              </div> -->
      </div>

    </div>
  </div>
</div>