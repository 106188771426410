<div class="modal-body">
  <div class="row">
    <button mat-icon-button style="margin-left: auto;" (click)="closePopup()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <br />
  <div class="row">
    <div class="col d-flex justify-content-center" id="delayedContentContainer">
      <div
        #elDelayedContent
        style="width: 100%;  max-width: 900px; word-wrap: break-word; "
      ></div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col" [ngStyle]="selectedPage.surveyContent.btnContainerStyle">
      <button
        type="button"
        class="btn"
        [ngStyle]="selectedPage.surveyContent.primaryBtnStyle"
        [style.backgroundColor]="selectedPage.surveyContent.primaryBtnBGColor"
        [style.color]="selectedPage.surveyContent.primaryBtnColor"
        [style.fontFamily]="selectedPage.surveyContent.primaryBtnFontStyle"
        [style.fontSize.pt]="selectedPage.surveyContent.primaryBtnFontSize"
        [style.width]="
          selectedPage.surveyContent.primaryBtnWidth == 'auto'
            ? 'auto'
            : selectedPage.surveyContent.primaryBtnWidth + 'px'
        "
        [style.height]="
          selectedPage.surveyContent.primaryBtnHeight == 'auto'
            ? 'auto'
            : selectedPage.surveyContent.primaryBtnHeight + 'px'
        "
      >
        {{ selectedPage.surveyContent.primaryBtnText }}
      </button>
    </div>
  </div>
</div>
