import { Injectable } from "@angular/core";
import { Client } from "../models/client.model";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) {}

  get() {
    return this.http.get(`${environment.apiURL}/v1/client`);
  }

  post(client: Client) {
    const body = {
      name: client.name,
      domains: client.domains.join(","),
      isEnabled: client.isEnabled ? 1 : 0,
      isDeleted: client.isDeleted ? 1 : 0,
    };

    return this.http.post(`${environment.apiURL}/v1/client/add`, body);
  }

  put(client: Client) {
    const body = {
      id: client.id,
      name: client.name,
      domains: client.domains.join(","),
      isEnabled: client.isEnabled ? 1 : 0,
      isDeleted: client.isDeleted ? 1 : 0,
    };
    return this.http.put(`${environment.apiURL}/v1/client/update`, body);
  }

}
