import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public PAGE_CHANGE = 'page_change';
  public PAGE_PREVIEW = 'page_preview';
  public PAGE_SURVEY_VARIANT = 'page_survey_variant';
  public DELAYED_SURVEY_CONTENT = 'delayed_survey_content';

  public DELAYED_LABEL = 'delayed_label';
  public DELAYED_COLOR = 'delayed_color';
  public DELAYED_BG_COLOR = 'delayed_bg_color';

  public SEND_INPUT_CONTENT = 'send_survey_content';
  public SEND_SELECTION_CONTENT = 'send_selection_content';
  
  public SEND_EMAIL_LABEL = 'send_email_label';
  public SEND_EMAIL_COLOR = 'send_email_color';
  public SEND_EMAIL_BG_COLOR = 'send_email_bg_color';

  public SEND_PHONE_LABEL = 'send_phone_label';
  public SEND_PHONE_COLOR = 'send_phone_color';
  public SEND_PHONE_BG_COLOR = 'send_phone_bg_color';

  public SEND_SUBMIT_TEXT = 'send_submit_label';
  public SEND_SUBMIT_COLOR = 'send_submit_color';
  public SEND_SUBMIT_BG_COLOR = 'send_submit_bg_color';

  public INVITE_CONTENT = 'invite_content';

  public SEND_SETTINGS_DATA = 'send_settings_data';

  public BUILD_OVERALL_SETTINGS = 'build_overall_settings';
  public DOWNLOAD_OVERALL_SETTINGS = 'download_overall_settings';

  public eventManager:EventEmitter;
  constructor() {
    this.eventManager = new EventEmitter();
  }
}
