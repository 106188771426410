import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  async compressBase64Image(base64String: string, mimeType: string): Promise<string> {
    console.log('Compressing image...');
    const byteString = atob(base64String);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const originalSize = byteString.length;
    console.log(`Original size: ${originalSize} bytes`);

    const blob = new Blob([ab], { type: mimeType });
    const file = new File([blob], 'image', { type: mimeType });
    const options = {
        maxSizeMB: 0.1,
        useWebWorker: true
    };

    const compressedBlob = await imageCompression(file, options);
    const compressedBase64 = await this.blobToBase64(compressedBlob);
    const compressedSize = compressedBase64.length * 0.75; // approximate size in bytes

    console.log(`Compressed size: ${compressedSize} bytes`);

    return compressedBase64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
  }

  private blobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
      });
  }

  async extractAndCompressBase64Images(html: string): Promise<string> {
      console.log('Starting extraction...');
      const base64ImageRegex = /<img[^>]+src="data:image\/(png|jpeg|jpg);base64,([^"]+)"[^>]*>/g;
      const promises: Promise<{ original: string, replacement: string }>[] = [];

      let match;
      while ((match = base64ImageRegex.exec(html)) !== null) {
          console.log('Match found...');
          const imgTag = match[0];
          const mimeType = `image/${match[1]}`;
          const base64String = match[2];

          const promise = this.compressBase64Image(base64String, mimeType)
              .then(compressedBase64 => {
                  console.log('Image compressed...');
                  return {
                      original: imgTag,
                      replacement: imgTag.replace(base64String, compressedBase64)
                  };
              })
              .catch(error => {
                  console.error('Error compressing image:', error);
                  return { original: imgTag, replacement: imgTag }; // Return the original tag in case of an error
              });
          promises.push(promise);
      }

      // Wait for all promises to complete
      const replacements = await Promise.all(promises);

      // Replace the original base64 strings with the compressed ones
      let newHtml = html;
      replacements.forEach(({ original, replacement }) => {
          console.log('Replacing image...');
          newHtml = newHtml.replace(original, replacement);
      });

      console.log("Compressed HTML: ", newHtml);
      return newHtml;
  }
}
