<mat-sidenav-container (backdropClick)="sidenav.close()" style="height: 100%;">
  <mat-sidenav
    #sidenav
    (keydown.escape)="sidenav.close()"
    opened="true"
    disableClose
    style="width: 400px;"
  >
    <!-- PUT YOUR CONFIG HERE -->
    <div class="col-lg-12">
      <div class="row">
        <a
          mat-icon-button
          (click)="sidenav.close()"
          style="margin-left: auto;"
          [disabled]="isLoading"
        >
          <mat-icon>close</mat-icon>
        </a>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1">View type</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              [(ngModel)]="OptionView"
              [disabled]="isLoading"
            >
              <option value="Background">Background</option>
              <option value="LiveWebsite">Live website</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="OptionView == 'LiveWebsite'">
        <div class="col-lg-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="http://yoursite.com/"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              [(ngModel)]="SiteData"
              [disabled]="isLoading"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="loadUrl()"
                [disabled]="isLoading"
                [ngStyle]="{ 'pointer-events': isLoading ? 'none' : 'all' }"
              >
                Load
              </button>
              <button
                class="btn btn-outline-secondary"
                type="button"
                [disabled]="isLoading"
                [ngStyle]="{ 'pointer-events': isLoading ? 'none' : 'all' }"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="OptionView == 'Background'">
        <div class="col-lg-12">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              (change)="imageUpload($event)"
              [disabled]="isLoading"
            />
            <label class="custom-file-label" for="customFile"
              >Background image</label
            >
          </div>
        </div>
        <!-- <div class="col-lg-12" style="margin-top: 10px;">
          <label for="picker">Background color</label>
          <input
            id="picker"
            class="picker__style"
            [(colorPicker)]="color"
            [style.background]="color"
            [cpPosition]="'bottom'"
          />
        </div> -->
      </div>
      <div class="row" *ngIf="isLoading && !errorMsg">
        <span
          class="spinner-border spinner-border-sm text-info"
          role="status"
          aria-hidden="true"
          style="width: 36px; height: 36px; margin: 16px auto 0 auto;"
        ></span>
      </div>
      <div class="row" *ngIf="errorMsg">
        <span class="badge badge-warning" style="margin: 16px auto 0 auto;"
          >Could not load website due to network issues.</span
        >
      </div>
      <div class="row" *ngIf="errorMsg">
        <button
          type="button"
          class="btn btn-info btn-sm"
          style="margin: 16px auto 0 auto;"
          *ngIf="errorMsg"
          (click)="errorMsg = null"
        >
          OK
        </button>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label>Page</label>
            <select
              class="form-control"
              [(ngModel)]="selectedPageIndex"
              (ngModelChange)="updateSelectedPage()"
              [disabled]="isLoading"
            >
              <option
                [value]="i"
                *ngFor="let page of project.pages; let i = index"
                >{{ page.pageName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" style="text-align: center;">
          <button
            type="button"
            class="btn btn-info"
            (click)="sidenav.close(); loadPopup()"
            [disabled]="isLoading"
            [ngStyle]="{ 'pointer-events': isLoading ? 'none' : 'all' }"
          >
            Reload Webpop
          </button>
        </div>
      </div>
      <hr />
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- CONVERT THIS TO FLOATING BUTTON -->

    <app-popup
      *ngIf="selectedPage"
      (displayFAB)="displayFAB()"
      id="popupModal"
      class="modal fade"
      role="dialog"
      data-backdrop="false"
    >
    </app-popup>

    <app-popup-ds
      id="popupDSModal"
      *ngIf="selectedPage && showFAB && !FabModal"
      [ngStyle]="modal_style"
      [style.width.px]="selectedPage.surveyContent.modalWidth"
      [style.height.px]="selectedPage.surveyContent.modalHeight"
      [style.borderRadius.px]="selectedPage.surveyContent.modalBorderRadius"
      [style.boxShadow]="selectedPage.surveyContent.modalBSStyle"
      class="modal-content"
    >
    </app-popup-ds>

    <button
      *ngIf="selectedPage && showFAB"
      ngDraggable
      id="btn_Fab"
      (click)="ShowDS()"
      [ngStyle]="Fab_style"
      [style.color]="selectedPage.surveyContent.floatingBtnColor"
      [style.backgroundColor]="selectedPage.surveyContent.floatingBtnBGColor"
      [style.opacity]="selectedPage.surveyContent.floatingBtnOpacity"
      [style.width.px]="selectedPage.surveyContent.floatingBtnWidth"
      [style.height.px]="selectedPage.surveyContent.floatingBtnHeight"
      [style.borderRadius.px]="
        selectedPage.surveyContent.floatingBtnBorderRadius
      "
      [style.boxShadow]="selectedPage.surveyContent.floatingBtnBSStyle"
    >
      <img
        [src]="selectedPage.surveyContent.floatingBtnIcon"
        [style.width.px]="selectedPage.surveyContent.floatingBtnWidth - 20"
        [style.height.px]="selectedPage.surveyContent.floatingBtnHeight - 20"
        alt=""
      />
    </button>

    <button class="setting__open" (click)="sidenav.open()">
      <mat-icon class="fab__icon">settings</mat-icon>
    </button>

    <!-- PUT YOUR WEBSITE/BACKGROUND IMAGE HERE -->
    <div class="preview__container">
      <div
        class="view__background"
        [style.background-color]="color"
        *ngIf="OptionView == 'Background'"
      >
        <img *ngIf="cardImageBase64" style="width: 100%; height: 100%;" [src]="cardImageBase64" />
      </div>
      <object
        id="website-container"
        onerror="error()"
        type="text/html"
        [attr.data]="data"
        width="100%"
        height="100%"
        style="overflow:hidden; padding: 0px;"
        *ngIf="OptionView == 'LiveWebsite'"
      >
      </object>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
