export class PopupModal {

    height: number;
    width: number;

    constructor() {
        this.height = 500;
        this.width = 400;
    }

}